import AOS from 'aos';

import 'swiper/css';
import 'aos/dist/aos.css';
import './src/styles/global.css';

AOS.init({
  once: true,
  disable: window.innerWidth < 768,
});
